<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <img src="/subject/live2.0/banner.png" alt="">
    </div>
    <div class="content">
      <div class="part">
        <img src="/subject/live2.0/part1.png" alt="">
        <div class="positionBox positionBox1">
          <img @click="goLive" src="/subject/live2.0/classBtn.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/into.png" alt="">
        </div>
        <!-- <img class="text" src="/subject/live2.0/text.png" alt=""> -->
      </div>
      <div class="part">
        <img src="/subject/live2.0/part2.png" alt="">
        <div class="positionBox">
          <img @click="goLive" src="/subject/live2.0/live.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/into.png" alt="">
        </div>
        <img class="text" src="/subject/live2.0/text.png" alt="">
      </div>
      <div class="part">
        <img src="/subject/live2.0/part3.png" alt="">
        <!-- <img @click="goLive" class="yuyue" src="/subject/live2.0/yuyue.png" alt=""> -->
        <div class="positionBox positionBox1">
          <img @click="goLive" src="/subject/live2.0/classBtn.png" alt="">
          <img @click="goRoom" src="/subject/live2.0/into.png" alt="">
        </div>
        <!-- <img class="text" src="/subject/live2.0/text.png" alt=""> -->
      </div>
    </div>
    <contactusVue></contactusVue>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name:'live2.0Pc',
  components:{
    ContactusVue
  },
  data(){
    return{
      navBarFixed: false,
      scroll: '',
      screenWidth: null,
    }
  },
  created(){
    this.$emit("controlShow", true);
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name: "live2.0Wap",
        });
      }
    },
  },
  methods:{
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    goLive(){
      window.open(this.$parent.live800UrlUc)
    },
    goRoom(){
      this.$router.push({
        name:'classRoom'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: auto;
    .tabbar {
      width: 100%;
      background-color: #ffffff;
      img {
        margin: 20px 50px;
        width: 130px;
      }
    }
    .navBarFixed {
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .banner{
      width: 100%;
      img{
        width: 100%;
        display: block;
      }
    }
    .content{
      width: 100%;
      min-width: 1680px;
      height: 4879px;
      background: url("/subject/live2.0/background.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .part{
        width: 100%;
        text-align: center;
        position: relative;
        margin-top: 35px;
        .positionBox{
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%,-50%);
          img{
            cursor: pointer;
          }
        }
        .positionBox1{
          bottom: -15px;
        }
        .text{
          position: absolute;
          bottom: 25px;
          left: 32.5%;
        }
        .yuyue{
          position: absolute;
          bottom: 14.6%;
          left: 33%;
          cursor: pointer;
        }
      }
    }
  }
</style>